.login.ui.grid {
  width: 100vw;
  height: 100vh;
}

.login .ui.image.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.login .overlay {
  background-color: #111111bf;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login.ui.grid > .title.column {
  /*background-color: #19212b;*/
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login.ui.grid > .form.column {
  background-color: #f7f7fa;
  color: #19212b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 4em;
  padding-right: 4em;
}

.login.ui.grid > .form.column > .ui.segment {
  max-width: 550px;
  width: 100%;
}

.login.ui.grid > .form.column > .ui.segment > .divider {
  padding: 0.75em 0em 0.75em 0em;
}

.login.ui.grid > .form.column > .ui.segment .loginButton {
  display: flex;
  justify-content: flex-end;
}

.login.ui.grid > .form.column .ui.message {
  width: 100%;
}
