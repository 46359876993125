.imageSelector {
  width: 100%;
}

.imageSelector > .button {
  display: block;
}

.imageSelector .ui.cards {
  margin-top: 0.5em;
}

.imageSelector .ui.cards > .ui.card {
  max-width: 250px;
}

.imageSelectorModal a.card {
  color: unset;
}

.imageSelectorModal .card > div.lazyImageContainer,
.imageSelectorModal .card > div.lazyImageContainer > div,
.imageSelectorModal .card > div.lazyImageContainer > div > div {
  height: 100%;
}

.imageSelectorModal .card > div.lazyImageContainer > div > div {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
