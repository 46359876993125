.numberPicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberPicker > .ui.basic.button,
.numberPicker > .ui.basic.button:focus,
.numberPicker > .ui.basic.button:hover {
  box-shadow: none;
}
