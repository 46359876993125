.fileUploadHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1em;
}

.fileUploadHeader > .copyright {
  flex-grow: 1;
}

.fileUploadHeader > div {
  margin-right: 1em;
}
