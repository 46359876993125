body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list:not(.icon) {
  padding-bottom: 2em;
}

/*
 * Adding padding to the top of item buttons.
 */
.item-list > .ui.items > .item > .content > .ui.button {
  margin-top: 0.75em;
}

/*
 * Update default textarea font to match other input elements. Not sure why Semantic UI has a different font.
 */
textarea,
.ui.form textarea {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/*
 * Remove blue color from breadcrumb UI.
 */
.ui.breadcrumb a {
  color: unset;
}

.ui.breadcrumb a:hover {
  color: unset;
  text-decoration: underline;
}

/*
 * Use the "truncate" class name on tables to allow long text blocks to be viewed and truncated.
 */
.ui.table > tbody > tr > td.truncate {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
