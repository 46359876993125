.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings > .ui.header:first-child {
  margin-bottom: 2em;
  margin-top: 2em;
  text-align: center;
}

.settings > .ui.cards > .ui.card {
  color: unset;
}

.settings > .ui.cards > .ui.card > .image {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
