.adminSidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 1em;
  background-color: #000000;
  z-index: 1001;
}

.adminSidebar > .ui.vertical.icon.menu {
  background: none;
  color: #f0f4ef;
  min-width: 100px;
  text-align: left;
}

.adminSidebar > .ui.vertical.icon.menu > .item {
  padding-top: 25px;
  padding-bottom: 25px;
}
